






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HeroTile extends Vue {
    @Prop() src!: string;
    @Prop() title!: string;

    hover = false;

    get cardAttrs() {
        return {
            class: `dn-hero-tile`
        }
    }

    toggleHover() {

    }
}
