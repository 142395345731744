











































import { Component, Prop, Vue } from 'vue-property-decorator';

import Glide from '@glidejs/glide'; 

import HeroTile from '@/components/ui/HeroTile.vue';
import PackTile from '@/components/ui/PackTile.vue';
import VideoTile from '@/components/ui/VideoTile.vue';


@Component({
    components: {
        HeroTile,
        PackTile,
        VideoTile
    }
})
export default class Carousel extends Vue {
    @Prop() title!: string;
    @Prop() subtitle!: string;
    @Prop() items!: [];
    @Prop() overflow!: boolean;
    @Prop({ default: 2.2 }) perView!: number;
    @Prop({ default: 16 }) gap!: number;
    @Prop() type!: string;
    @Prop({ default: () => {  } }) breakpoints!: any;

    glide: any;

    updated() {
        this.initCarousel();
    }

    mounted() {
        this.initCarousel();
    }

    initCarousel() {
        const options = {
            type: 'slider',
            perView: this.perView,
            gap: this.gap,
            breakpoints: this.breakpoints
        }

        this.glide = new Glide(this.$refs.glide, options).mount();
    }

    get carouselAttrs() {
        return {
            class: `dn-carousel
                    ${this.overflow ? ' dn-carousel--overflow' : ''}`,
            ref: 'glide'
        }
    }
}
