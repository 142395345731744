










import { Component, Prop, Vue } from 'vue-property-decorator';

import { VimeoService } from '@/services/vimeo.service';

@Component({})
export default class VideoTile extends Vue {
    @Prop() video!: any;

    thumbnail = '';

    mounted() {
        this.load();
    }

    async getThumbnail() {
        return await VimeoService.getThumbnail(this.video.vimeoId);
    }

    async load() {
        this.thumbnail = await this.getThumbnail() || this.video.thumbnailUrl;
    }
}
